import React, { Component } from 'react';

import Header from '../Header/Header';
import ShareButtons from '../ShareButtons/ShareButtons';
import Modal from '../Modal/Modal';
import Card from '../Card/Card';

import './CardScreen.scss';

class CardScreen extends Component {

	componentDidMount() {
		if (!this.props.data.scores) this.props.resetScore();
	}

	render(){

		const props = this.props;
		const { themas } = props.data;
		const cards = themas.map((thema, i) => (
			<Card key={"card"+i} thema={thema} {...props} />)
		);

		return (
            <div className="cardscreen">
                <Header {...props}  />
                <ShareButtons />

                <div className='wrapper'>
                    <h3 className='page-title'>Welk thema is voor u het belangrijkst als u nadenkt over pensioen?</h3>

                    <div className="cards">
                        {cards}
                    </div>
                </div>

                
                <Modal open={props.data.modal} toggleModal={props.toggleModal} />
            </div>
		)
	}
}

export default CardScreen;
