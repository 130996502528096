import React, {Component} from 'react';
import Deel from '../Icons/Deel';
import Twitter from '../Icons/Twitter';
import Facebook from '../Icons/Facebook';
import LinkedIn from '../Icons/LinkedIn';
import Mail from '../Icons/Mail';
import './ShareButtons.scss';


class ShareButtons extends Component {

  state = { open: false }

	handleClick = (e) => {
		e.preventDefault();
		this.setState({open: !this.state.open})
	}

	render(){

  const url = "https://werknemer.keuzehulppensioen.nl",
  			title = "Keuzehulp Pensioen",
  			text = "Wat is voor jou belangrijk als je nadenkt over pensioen?",
  			text2 = text + " Doe de test op " + url + " en krijg binnen een paar minuten persoonlijk advies.";

		return (
		  <div className={this.state.open ? "sharebuttons open" : "sharebuttons"}>
		  	<span className="label" onClick={this.handleClick}>
		  		<Deel />
		  	</span>
		  	<a
						href={"https://twitter.com/intent/tweet/?text=" + text + " %23pensioen&amp;url=" + url }
						target="_blank"
						rel="noopener noreferrer"
						aria-label="Deel deze pagina via Twitter.">
					  	<span className="socialbutton twitter">
					  		<Twitter />
					  	</span>
				</a>
				<a
						href={"https://facebook.com/sharer/sharer.php?u=" + url}
						target="_blank"
						rel="noopener noreferrer"
						aria-label="Deel deze pagina op Facebook.">
					  	<span className="socialbutton facebook">
					  		<Facebook />
					  	</span>
		  	</a>
				<a
						href={"https://www.linkedin.com/shareArticle?mini=true&amp;url=" + url + "&amp;title=" + title + "&amp;summary=" + text2 }
						target="_blank"
						rel="noopener noreferrer"
						aria-label="Deel deze pagina via LinkedIn">
				  	<span className="socialbutton linkedin">
				  		<LinkedIn />
				  	</span>
		  	 </a>
		  	<a
						href={"mailto:?subject=" + title + "&body=" + encodeURIComponent(text2)}
						target="_self"
						aria-label="Deel deze pagina via e-mail.">
					  	<span className="socialbutton mail">
					  		<Mail />
					  	</span>
		  	</a>
			</div>
		)
	}
}

export default ShareButtons;






