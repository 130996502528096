import React from 'react';

import './Steeg.scss';

const Steeg = (props) => {

	const changeHandler = (event) => {
		props.updateScore(props.data.huidigeThema, props.steeg.id, +event.target.value);
	}

	const huidigeScore = props.data.scores.filter((score) => (
		score.steegID === props.steeg.id &&
		score.themaID === props.data.huidigeThema
		))[0].score;

	return (
		<tr className="steeg">
			<td>{props.steeg.kop}</td>
			<td className="label">
				<label>
					<input type="radio" onChange={changeHandler} name={props.steeg.id} value={-1} checked={huidigeScore === -1} />
				</label>
				<span className={(huidigeScore === -1) ? "radio checked" : "radio"} />
			</td>
			<td className="label">
				<label>
					<input type="radio" onChange={changeHandler} name={props.steeg.id} value={0} checked={huidigeScore === 0} />
				</label>
				<span className={(huidigeScore === 0) ? "radio checked" : "radio"} />
			</td>
			<td className="label">
				<label>
					<input type="radio" onChange={changeHandler} name={props.steeg.id} value={1} checked={huidigeScore ===1}/>
				</label>
				<span className={(huidigeScore === 1) ? "radio checked" : "radio"} />
			</td>
		</tr>
	)
}

export default Steeg;
