import React from 'react';
import StraatResult from '../Straat/StraatResult';
import './WijkResult.scss';

const WijkResult = (props) => {

	const relevantItems = (straatID, themaID) => props.data.scores.filter((item) => (
			themaID === item.themaID &&
			straatID === item.steegID.slice(0,3) &&
			item.score > 0
	));

	const straten = props.wijk.straten
		.filter((straat) => (
			straat.themas.indexOf(props.data.huidigeThema) > -1 &&
			relevantItems(straat.id, props.data.huidigeThema).length  > 0
		))
		.map((straat) => (
			<StraatResult key={"straat"+straat.id} straat={straat} data={props.data} />
		));

	return (
	  <div className={"wijkresult wijk"+props.wijk.id} >
			<h3 className={"kopwijk"+props.wijk.id + " kopwijk"}>{props.wijk.naam}</h3>
			{straten}
		</div>
	)
}

export default WijkResult;
