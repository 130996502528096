import React from 'react';
import SteegResult from '../Steeg/SteegResult';
import './StraatResult.scss';

const StraatResult = (props) => {

	const relevantItems = (steegID, themaID) => props.data.scores.filter((item) => (
		item.steegID === steegID &&
		item.themaID === themaID &&
		item.score === 1
		));

 const stegen = props.straat.stegen
		.filter((steeg) => (
			relevantItems(steeg.id, props.data.huidigeThema).length  > 0
		))
		.map((steeg) => (
			<SteegResult key={"steeg"+steeg.id} steeg={steeg} data={props.data} />
		));

	return (
	  <div className={"straatresult straat"+props.straat.id} >
	  	<table>
				<thead>
					<tr>
						<td><h6 className="kopstraat">{props.straat.kop}</h6></td>
					</tr>
				</thead>
				<tbody>
					{stegen}
				</tbody>
			</table>
		</div>
	)
}

export default StraatResult;
