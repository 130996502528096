import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import registerServiceWorker from './registerServiceWorker';

import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import SplashScreen from './components/SplashScreen/SplashScreen';
import CardScreen from './components/CardScreen/CardScreen';
import OptionScreen from './components/OptionScreen/OptionScreen';
import ExitScreen from './components/ExitScreen/ExitScreen';

import './index.scss';

import { themas } from './data/themas.js';
import { wijken } from './data/wijken.js';

class App extends Component {

	constructor(props) {
    super(props);
    this.state = {
    	themas: themas,
    	wijken: wijken
    }
  }

  updateThema = (id) => {
		this.setState({ huidigeThema: id})
  }

  resetScore = () => {
		const { wijken } = this.state;
		let scores = [];
		wijken.forEach((wijk) => {
				wijk.straten.forEach((straat) => {
					straat.stegen.forEach((steeg) => {
						const steegID = steeg.id;
						steeg.themas.forEach((themaID) => scores.push({
								themaID, steegID, score: 0
						 }))
					})
				})
			})
		this.setState({ scores });
  }

  updateScore = (themaID, steegID, score) => {
  	let scores = this.state.scores
  		.map((item) => {
  			if ( item.themaID === themaID && item.steegID === steegID) item.score = score;
  			return item;
  		});
  	this.setState({ scores });
  }

  toggleModal = (e) => {
  	this.setState({ modal: !this.state.modal });
  }

  toggleMailModal = (e) => {
  	this.setState({ mailmodal: !this.state.mailmodal });
  }

	render(){

		const RedirectToSplash = () => <Redirect to={{ pathname: '/'}}/>;

		return (
		  <Router>
		  <ScrollToTop>
			  <Switch>
			      <Route exact path="/" render={ () =>
			      	<SplashScreen resetScore={this.resetScore} />
			      }/>
			      <Route path="/stap1" render={ (props) =>
			      	<CardScreen data={this.state} updateThema={this.updateThema} toggleModal={this.toggleModal} resetScore={this.resetScore} {...props}  />
			      }/>
			      <Route path="/stap2" render={ (props) =>
			      	this.state.huidigeThema ?
			      	<OptionScreen data={this.state} updateScore={this.updateScore} toggleModal={this.toggleModal} {...props} /> :
			      	<RedirectToSplash />
			      }/>
			      <Route path="/stap3" render={ (props) =>
			      	this.state.huidigeThema ?
			      	<ExitScreen data={this.state} toggleModal={this.toggleModal} toggleMailModal={this.toggleMailModal} {...props} /> :
			      	<RedirectToSplash />
			      }/>
			      <Route render={ ()=> <RedirectToSplash />} />
			  </Switch>
			  </ScrollToTop>
		  </Router>
	)}
}

ReactDOM.render(
  <App />,
  document.getElementById('root')
);
registerServiceWorker();
