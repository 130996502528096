import React from 'react';
import Straat from '../Straat/Straat';
import './Wijk.scss';

const Wijk = (props) => {

	const straten = props.wijk.straten
		.filter((straat) => {
			return (straat.themas.indexOf(props.data.huidigeThema) > -1)}
			)
		.map((straat) => (
			<Straat key={"straat"+straat.id} straat={straat} data={props.data} updateScore={props.updateScore} />
		));

	return (
	    <div className={"wijk wijk"+props.wijk.id} >
			<h3 className='wijk-naam'>{props.wijk.naam}</h3>
			{straten}
		</div>
	)
}

export default Wijk;
