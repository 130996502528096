import React from 'react';
import { Link } from 'react-router-dom';

import Pijl from '../Icons/Pijl';
import Info from '../Icons/Info';
import LogoABP from '../Icons/LogoABP';

import './Header.scss';

const Header = (props) => {

		const location = props.location.pathname;
		let number;
		if (location.indexOf("/stap") > -1) number = +location[5];

		const stepper = (number) => {
			const steps = [];
			for (var i = 0; i < number; i++) {
                if (i === 2) steps.push(<div key={"step"+i} className="step last"></div>);
				else steps.push(<div key={"step"+i} className="step"></div>);
			}
			return steps;
		}

		const handleClickBack = () => {
			let page = number > 1 ? "/stap"+(number-1) : "/";
			if (props.data.huidigeThema === 7) page = "/stap1";
			props.history.push(page);
		}

		return (
            <div className="header">
                <div className="backbutton" onClick={handleClickBack}>
                    <Pijl color='#282D32' />
                </div>

                <LogoABP />

                <Link to='/'>
                    <h6 className='title'>Keuzehulp: doorwerken of pensioen?</h6>
                </Link>

                <div className="infobutton" onClick={props.toggleModal}>
                    <Info />
                </div>

                <div className="stepper">
                    {stepper(number)}
                </div>
            </div>
		)
}

export default Header;
