import React from 'react';

import WijkResult from '../Wijk/WijkResult';

const Mail = (props) => {

	const thema = props.data.themas[props.data.huidigeThema-1];
	const {tekst2, tekst3} = thema;

	const relevantItems = (wijkID) => props.data.scores.filter((item) => (
			props.data.huidigeThema === item.themaID &&
			wijkID === item.steegID[0] &&
			item.score > 0
		));

	const wijken = props.data.wijken
		.filter((wijk) => (
			wijk.themas.indexOf(props.data.huidigeThema) > -1) &&
			relevantItems(wijk.id).length  > 0
		)
		.map((wijk, i) => (
			<WijkResult key={"wijk"+wijk.id} wijk={wijk} data={props.data} updateScore={props.updateScore}  />
		));

	return(
			<html>
		  <head>
		    <meta name="viewport" content="width=device-width" />
		    <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
				<style dangerouslySetInnerHTML={{__html: `
				      img {
				        border: none;
				        -ms-interpolation-mode: bicubic;
				        max-width: 100%; }
				      body {
				        background-color: #f6f6f6;
				        font-family: sans-serif;
				        -webkit-font-smoothing: antialiased;
				        font-size: 14px;
				        line-height: 1.4;
				        margin: 0;
				        padding: 0;
				        -ms-text-size-adjust: 100%;
				        -webkit-text-size-adjust: 100%; }
				      table {
				        border-collapse: separate;
				        mso-table-lspace: 0pt;
				        mso-table-rspace: 0pt;
				        width: 100%; }
				        table td {
				          font-family: sans-serif;
				          font-size: 14px;
				          vertical-align: top; }
				      .body {
				        background-color: #f6f6f6;
				        width: 100%; }
				      .container {
				        display: block;
				        Margin: 0 auto !important;
				        /* makes it centered */
				        max-width: 580px;
				        padding: 10px;
				        width: 580px; }
				      .content {
				        box-sizing: border-box;
				        display: block;
				        Margin: 0 auto;
				        max-width: 580px;
				        padding: 10px; }
				      .main {
				        background: #ffffff;
				        border-radius: 3px;
				        width: 100%; }
				      .wrapper {
				        box-sizing: border-box;
				        padding: 20px; }
				      .content-block {
				        padding-bottom: 10px;
				        padding-top: 10px;
				      }
				      .footer {
				        clear: both;
				        Margin-top: 10px;
				        text-align: center;
				        width: 100%; }
				        .footer td,
				        .footer p,
				        .footer span,
				        .footer a {
				          color: #999999;
				          font-size: 12px;
				          text-align: center; }
				      h1,
				      h2,
				      h3,
				      h4 {
				        color: #000000;
				        font-family: sans-serif;
				        font-weight: 400;
				        line-height: 1.4;
				        margin: 0;
				        Margin-bottom: 30px; }
				      h1 {
				        font-size: 35px;
				        font-weight: 300;
							}
				      p,
				      ul,
				      ol {
				        font-family: sans-serif;
				        font-size: 14px;
				        font-weight: normal;
				        margin: 0;
				        Margin-bottom: 15px; }
				        p li,
				        ul li,
				        ol li {
				          margin-left: 5px; }
				      a {
				        color: #3498db;
				        text-decoration: underline; }
				      .last {
				        margin-bottom: 0; }
				      .first {
				        margin-top: 0; }
				      .align-center {
				        text-align: center; }
				      .align-right {
				        text-align: right; }
				      .align-left {
				        text-align: left; }
				      .clear {
				        clear: both; }
				      .mt0 {
				        margin-top: 0; }
				      .mb0 {
				        margin-bottom: 0; }
				      .preheader {
				        color: transparent;
				        display: none;
				        height: 0;
				        max-height: 0;
				        max-width: 0;
				        opacity: 0;
				        overflow: hidden;
				        mso-hide: all;
				        visibility: hidden;
				        width: 0; }
				      @media only screen and (max-width: 620px) {
				        table[class=body] h1 {
				          font-size: 28px !important;
				          margin-bottom: 10px !important; }
				        table[class=body] p,
				        table[class=body] ul,
				        table[class=body] ol,
				        table[class=body] td,
				        table[class=body] span,
				        table[class=body] a {
				          font-size: 16px !important; }
				        table[class=body] .wrapper,
				        table[class=body] .article {
				          padding: 10px !important; }
				        table[class=body] .content {
				          padding: 0 !important; }
				        table[class=body] .container {
				          padding: 0 !important;
				          width: 100% !important; }
				        table[class=body] .main {
				          border-left-width: 0 !important;
				          border-radius: 0 !important;
				          border-right-width: 0 !important; }
				        table[class=body] .btn table {
				          width: 100% !important; }
				        table[class=body] .btn a {
				          width: 100% !important; }
				        table[class=body] .img-responsive {
				          height: auto !important;
				          max-width: 100% !important;
				          width: auto !important; }
				        }
				      @media all {
				        .ExternalClass { width: 100%; }
				        .ExternalClass,
				        .ExternalClass p,
				        .ExternalClass span,
				        .ExternalClass font,
				        .ExternalClass td,
				        .ExternalClass div { line-height: 100%; }
							}
								.kader {
						        border: 1px solid #999;
						        border-radius: 9px;
						        padding: 0px 0px 6px 0px;
						        width: 100%;
						        overflow: hidden;
						        box-sizing: border-box;
						    }
								h1 {
						    	font-size: 18px;
						    	font-weight: 600;
						    	margin: 0;
						    }
						    h3 {
						    	font-size: 15px;
						    	font-weight: 600;
						    	margin: 18px 0px;
						    }
						   	p {
						   		font-size: 15px;
						    	margin: 0px 12px 12px 12px;
						    	padding: 0;
						    }
								ul {
								    margin: 0 15px 12px 30px;
								    padding: 0px;
								}
								li {
								    padding-left: 0px;
								    font-size: 15px;
								}
				        .station {
				        		display: none;
				            position: absolute;
				            top: 3px;
				            left: 0px;
				            width: 9px;
				            height: 9px;
				        }
						    .kopwijk {
						    	margin: 0;
						    	padding: 12px;
						    	color: #fff;
						    }
								.kopstraat {
									padding: 0;
									margin: 6px 12px 0px 12px;
						    	font-size: 15px;
						    }
								.steeg {
									margin: 0px;
									padding: 6px 12px 0px 12px;
						    	font-size: 15px;
						    }
						    table {
						    	margin-bottom: 10px;
						    }
						    .kopwijk1,
						    .kopwijk2,
						    .kopwijk3,
						    .kopwijk4,
						    .kopwijk5,
						    .kopwijk6,
						    .kopwijk7 {
									margin: 0;
						    	color: #fff;
						    	font-size: 15px;
						    	padding: 12px;
						    	mso-padding-alt: 0px;
						    }
								.kopwijk1 {
									background-color: #359aba;
									mso-border-alt: 12px solid #359aba;
								}
								.kopwijk2 {
									background-color: #d2711b;
									mso-border-alt: 12px solid #d2711b;
								}
								.kopwijk3 {
									background-color: #aeb626;
									mso-border-alt: 12px solid #aeb626;
								}
								.kopwijk4 {
									background-color: #66267f;
									mso-border-alt: 12px solid #66267f;
								}
								.kopwijk5 {
									background-color: #bc132e;
									mso-border-alt: 12px solid #bc132e;
								}
								.kopwijk6 {
									background-color: #f3ce11;
									mso-border-alt: 12px solid #f3ce11;
								}
								.kopwijk7 {
									background-color: #2c7267;
									mso-border-alt: 12px solid #2c7267;
								}
				    `}} />
				</head>
				  <body className="">
				    <table border="0" cellpadding="0" cellspacing="0" className="body">
				      <tr>
				        <td>&nbsp;</td>
				        <td className="container">
				          <div className="content">
				            <span className="preheader">Keuzehulp: doorwerken of pensioen?</span>
				            <table className="main">
				              <tr>
				                <td className="wrapper">
				                  <table border="0" cellpadding="0" cellspacing="0">
				                    <tr>
				                      <td>
				                        <h1>Keuzehulp: doorwerken of pensioen?</h1>
				                        <h3 className="kop">Dit kunt u doen</h3>
                      		      <div className="kader">
															    <p className="advies"><br /><strong>{tekst3}</strong></p>
															    <p className="thema">{tekst2} Hieronder ziet u alleen de onderwerpen die u belangrijk vindt.</p>
													      </div>
																<h3 className="kop">Uw persoonlijke overwegingen</h3>
													      <div className="kader">
													        {wijken}
													      </div>
				                      </td>
				                    </tr>
				                  </table>
				                </td>
				              </tr>
				            </table>
				          </div>
				        </td>
				        <td>&nbsp;</td>
				      </tr>
				    </table>
				  </body>
				</html>
	  )
}
export default Mail;
