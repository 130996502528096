import React from 'react';

import './Steeg.scss';

const Steeg = (props) => {

	return (
		<tr>
			<td className="steeg">{props.steeg.kop}</td>
		</tr>
	)
}

export default Steeg;
