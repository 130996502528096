import React from 'react';

const Mail = ({ color = '#FFFFFF' }) => (
	<svg viewBox="0 0 44 44" className="icon mail">
		<rect x="8" y="12" width="28" height="20" fill={color} />
		<polyline points="7.5 12 22 22 36.5 12" fill={color} strokeMiterlimit="10" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
	</svg>
);

export default Mail;

