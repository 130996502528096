import React from 'react';

const Deel = () => (
	<svg viewBox="0 0 44 44" className="icon deel">
    <polyline points="28 16 16 22 28 28" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3" />
    <circle cx="28" cy="16" r="4" fill="#fff" />
    <circle cx="28" cy="28" r="4" fill="#fff" />
    <circle cx="16" cy="22" r="4" fill="#fff" />
  </svg>
);

export default Deel;
