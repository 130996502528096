import React from 'react';

import Button from '../Button/Button'

import './Card.scss';

const Card = (props) => {

	const clickHandler = () => {
		props.updateThema(props.thema.id);
		props.thema.id === 7 ? props.history.push("/stap3") : props.history.push("/stap2");
	}

	return (
	    <div className={"card card" + props.thema.id} >
			<h3 className='card-title'>{props.thema.naam}</h3>
			<p>{props.thema.tekst1}</p>
            <Button 
                designType='secondary'
                text="Invullen"
                icon='pijl'
                iconPosition='left'
                clickHandler={clickHandler}
            />
		</div>
	)
}

export default Card;
