import React from 'react';

const teksten = {
	colofon: {
		kop: "Hoe is deze app tot stand gekomen?",
		tekst: <div>
						<p>Deze Keuzehulp Pensioen helpt u om goede beslissingen te nemen rondom uw pensioen. De keuzehulp geeft u inzicht in argumenten en afwegingen die relevant zijn voor u. Hiervoor leggen we u een aantal argumenten voor. Aan het einde van de app ziet u welke opties u heeft.</p>
						<p>Deze app is gemaakt door <a href='https://www.argumentenfabriek.nl' target='_blank' rel='noopener noreferrer'>De Argumentenfabriek</a> in opdracht van <a href='https://www.apg.nl/nl' target='_blank' rel='noopener noreferrer'>APG</a>. Er is een app voor werknemers en voor werkgevers. Naast deze apps heeft De Argumentenfabriek twee Argumentenkaarten gemaakt. Deze kaarten bevatten een overzicht van mogelijke afwegingen als u twijfelt tussen doorwerken of met pensioen gaan. De afwegingen in de app komen uit deze Argumentenkaarten.</p>
					</div>
		}
}

export { teksten }




