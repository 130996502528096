import React from 'react';

const Home = ({ color = '#FFFFFF' }) => (
	<svg viewBox="8 8 36 36" className="icon mail">
		<path d="M15,32h5V26h4v6h5a1,1,0,0,0,1-1V23h1.79a.5.5,0,0,0,.35-.85l-9.44-9.44a1,1,0,0,0-1.41,0l-9.44,9.44a.5.5,0,0,0,.35.85H14v8A1,1,0,0,0,15,32Z" fill={color} />
	</svg>
);

export default Home;

