import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Button from '../Button/Button';

import './SplashScreen.scss';

class Splash extends Component {

	componentDidMount() {
		this.props.resetScore();
	}

	render() {
		return (
            <div className="splashscreen">
                <h1>Wat is voor u belangrijk als u nadenkt over pensioen?</h1>
                <h2>Doe de test en weet binnen een paar minuten wat u kunt doen.</h2>
                <Link to="/stap1" style={{ textDecoration: 'none' }}>
                    <Button designType='primary' text='Start' icon='pijl' iconPosition='left'/>
                </Link>
            </div>
		)
	}
}

export default Splash;
