import React from 'react';

const themas = [
	{
		"id": 1,
		"naam": <span>Keuzemogelijk&shy;heden</span>,
		"tekst1": "Ik wil een overzicht van de keuzemogelijkheden.",
		"tekst2": "U wilt een overzicht van uw keuzemogelijkheden.",
		"tekst3": <span>Log met uw DigiD in op <a href='https://mijn.abp.nl/' target='_blank' rel='noopener noreferrer'>mijn.abp.nl</a>. Stel zelf uw pensioen samen. U kunt direct zien welke gevolgen dit heeft voor de hoogte van uw pensioen.</span>
	},
	{
		"id": 2,
		"naam": "Eerder stoppen",
		"tekst1": "Ik wil niet zo lang meer werken en wil weten wat mijn mogelijkheden zijn.",
		"tekst2": "U wilt niet zo lang meer werken en wilt weten wat uw mogelijkheden zijn.",
		"tekst3": <span>Op <a href='https://www.abp.nl/plan-uw-pensioen/welke-pensioenkeuzes-heeft-u/eerder-stoppen.aspx' target='_blank' rel='noopener noreferrer'>abp.nl</a> vindt u meer informatie over wat eerder stoppen met werken betekent voor uw pensioen.</span>
	},
	{
		"id": 3,
		"naam": "Minder werken",
		"tekst1": "Ik wil minder werken of met verlof, maar ik ben bang dat ik dan te veel inlever.",
		"tekst2": "U wilt minder werken of met verlof maar u bent bang dat u te veel inlevert.",
		"tekst3": <span>Ga in gesprek met uw werkgever. Neem contact op met de HR-adviseur van uw organisatie. U kunt ook inloggen met uw DigiD op <a href='https://mijn.abp.nl/' target='_blank' rel='noopener noreferrer'>mijn.abp.nl</a>. Stel zelf uw pensioen samen. U kunt direct zien welke gevolgen dit heeft voor de hoogte van uw pensioen.</span>
	},
	{
		"id": 4,
		"naam": "Inkomen en pensioenkeuzes",
		"tekst1": "Ik wil weten wat de gevolgen zijn van mijn pensioenkeuzes op mijn inkomen.",
		"tekst2": "U wilt weten wat de gevolgen zijn van uw pensioenkeuzes op uw inkomen.",
		"tekst3": <span>U kunt een aantal dingen doen. Log met uw DigiD in op <a href='https://mijn.abp.nl/' target='_blank' rel='noopener noreferrer'>mijn.abp.nl</a>. Stel zelf uw pensioen samen. U kunt direct zien welke gevolgen dit heeft voor de hoogte van uw pensioen. U kunt ook met een onafhankelijk financieel adviseur praten over uw situatie. Via internet kunt u een adviseur vinden.</span>
	},
	{
		"id": 5,
		"naam": "Inkomen en werk",
		"tekst1": "Ik wil weten wat het voor mijn inkomen betekent als ik eerder of later stop met werken.",
		"tekst2": "U wilt weten wat het voor uw inkomen betekent als u eerder of later stopt met werken.",
		"tekst3": <span>U kunt een aantal dingen doen. Log met uw DigiD in op <a href='https://mijn.abp.nl/' target='_blank' rel='noopener noreferrer'>mijn.abp.nl</a>. Stel zelf uw pensioen samen. U kunt direct zien welke gevolgen dit heeft voor de hoogte van uw pensioen. U kunt ook met een onafhankelijk financieel adviseur praten over uw situatie. Via internet kunt u een adviseur vinden.</span>
	},
	{
		"id": 6,
		"naam": "Boventallig",
		"tekst1": "Ik word boventallig verklaard en wil weten of met pensioen gaan een optie is.",
		"tekst2": "U wordt boventallig verklaard en wilt weten of met pensioen gaan een optie is.",
		"tekst3": "Situaties als de uwe vergen onafhankelijk juridisch en financieel advies. Praat met een onafhankelijk adviseur over uw situatie. Via internet kunt u een adviseur vinden."

	},
	{
		"id": 7,
		"naam": "Privésituatie",
		"tekst1": "Mijn privésituatie verandert en ik wil weten welke invloed dit heeft op mijn pensioen.",
		"tekst3": <span>Als er iets verandert in uw privesituatie, kan dat van invloed zijn op uw pensioen. Bijvoorbeeld als u gaat trouwen, werkloos wordt of als u arbeidsongeschiktheid raakt. Op  <a href='https://www.abp.nl/uw-situatie-verandert/' target='_blank' rel='noopener noreferrer'>abp.nl</a> vindt u meer informatie over wat deze en andere veranderingen betekenen voor uw pensioen.</span>
	}
];
export { themas }

