import React, { Component } from 'react';

import Header from '../Header/Header';
import ShareButtons from '../ShareButtons/ShareButtons';
import Modal from '../Modal/Modal';
import MailModal from '../MailModal/MailModal';
import WijkResult from '../Wijk/WijkResult';
import Button from '../Button/Button';

import './ExitScreen.scss';

class ExitScreen extends Component {

    showPrint = () => {
        window.print();
    }

	toSplash = () => this.props.history.push("/");

	render() {
        const props = this.props;
        const thema = props.data.themas[props.data.huidigeThema-1];
        let thematekst = thema.tekst2;
        const advies = thema.tekst3;

        (thematekst) ? thematekst += " Hieronder ziet u alleen de onderwerpen die u belangrijk vindt." : thematekst = "";

        const relevantItems = (wijkID, themaID) => props.data.scores.filter((item) => (
            themaID === item.themaID &&
            wijkID === item.steegID[0] &&
            item.score > 0
        ));

        const wijken = props.data.wijken
            .filter((wijk) => (
                wijk.themas.indexOf(props.data.huidigeThema) > -1 &&
                relevantItems(wijk.id, props.data.huidigeThema).length  > 0
            ))
            .map((wijk, i) => (
                <WijkResult key={"wijk"+wijk.id} wijk={wijk} data={props.data} />
            ));

		return (
            <div className="exitscreen">

                <Header {...props}  />
                <ShareButtons />

                <div className='wrapper'>
                    <h3 className="kop">Dit kunt u doen</h3>

                    <div className="top">
                        <div className="kader">
                            <p className="advies">{advies}</p>
                            <p className="thema">{thematekst}</p>
                        </div>

                        <div className="buttons">
                            <Button text="Mail mijn advies" icon="mail" iconPosition='left' clickHandler={props.toggleMailModal} />
                            <Button text="Print mijn advies" icon="print" iconPosition='left' clickHandler={this.showPrint} />
                            <Button designType='primary-ghost' text="Terug naar begin" icon="home" iconPosition='left' clickHandler={this.toSplash} />
                        </div>
                    </div>

                    <div className="wijken">
                        {wijken}
                    </div>
                </div>
                
                <Modal open={props.data.modal} toggleModal={props.toggleModal} />
                <MailModal open={props.data.mailmodal} toggleMailModal={props.toggleMailModal} mailResult={this.mailResult} {...props} />
            </div>
        )
	}
}
export default ExitScreen;
