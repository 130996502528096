import React from 'react';
import Steeg from '../Steeg/Steeg';
import './Straat.scss';

const Straat = (props) => {


 const stegen = props.straat.stegen
		.filter((steeg) => {
		return (steeg.themas.indexOf(props.data.huidigeThema) > -1)}
		)
	.map((steeg) => (
		<Steeg key={"steeg"+steeg.id} steeg={steeg} data={props.data}  updateScore={props.updateScore} />
	));

	return (
	  <div className={"straat straat"+props.straat.id} >
	  	<table>
				<thead>
					<tr>
						<td><h6>{props.straat.kop}</h6></td>
						<td className="label">Niet belang&shy;rijk</td>
						<td className="label">Neu&shy;traal</td>
						<td className="label">Belang&shy;rijk</td>
					</tr>
				</thead>
				<tbody>
					{stegen}
				</tbody>
			</table>
		</div>
	)
}

export default Straat;
