import React from 'react';
import PropTypes from 'prop-types';

import Pijl from '../Icons/Pijl.js';
import Mail from '../Icons/Mail';
import Print from '../Icons/Print';
import Home from '../Icons/Home';

import './Button.scss';

const Button = (props) => {

	const { designType = 'primary', icon, iconPosition, greyedOut, clickHandler, iconFlipped = false, style, classes } = props;

	const iconColor = (() => {
		if (designType === 'secondary-ghost') return '#009CBD';
		if (designType === 'primary-ghost') return '#ED7102';
		else return '#FFFFFF'
	})()

	const icons = {
		pijl: <Pijl color={iconColor} />,
		mail: <Mail color={iconColor} />,
		print: <Print color={iconColor} />,
		home: <Home color={iconColor} />
	}

	let className = 'button-';
	className = className + designType;
	if (iconPosition) className = className + ' ' + iconPosition;
	if (greyedOut === true) className = className + ' greyedOut';
	if (iconFlipped) className = className + ' icon-flipped';

	return (
	  	<button style={style} className={classes !== undefined ? className + ' ' + classes : className} onClick={clickHandler} >
			{props.text}
			{icons[icon]}
		</button>
	)
}

Button.propTypes = {
	designType: PropTypes.oneOf(['primary', 'secondary', 'primary-ghost', 'secondary-ghost']),
	text: PropTypes.string,
	icon: PropTypes.oneOf(['pijl', 'mail', 'print', 'vuilnisbak', 'home']),
	iconPosition: PropTypes.oneOf(['left', 'right']),
	greyedOut: PropTypes.bool,
	clickHandler: PropTypes.func,
	iconFlipped: PropTypes.bool,
    classes: PropTypes.string
}
export default Button;
