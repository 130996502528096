import React from 'react';

const wijken = [{
        "id": "1",
        "naam": "Mijn werk",
        "themas": [1, 2, 5, 6],
        "straten": [{
            "id": "1-1",
            "kop": "Ik wil wel blijven werken, maar het moet anders dan nu",
            "themas": [1, 2, 5],
            "stegen": [{
                "id": "1-1-1",
                "kop": "Ik wil met deeltijdpensioen of flexibelere werktijden.",
                "themas": [1, 2, 5]
            }, {
                "id": "1-1-2",
                "kop": "Ik wil minder werken en meer vrije tijd.",
                "themas": [1, 2]
            }, {
                "id": "1-1-3",
                "kop": "Ik wil andere taken dan nu, bijvoorbeeld minder zware verantwoordelijkheden of meer werken aan kennisoverdracht.",
                "themas": [1, 2, 5]
            }, {
                "id": "1-1-4",
                "kop": "Ik zie aantrekkelijke kansen voor een andere baan of het beginnen van mijn eigen bedrijf.",
                "themas": [1, 2, 5]
            }]
        }, {
            "id": "1-2",
            "kop": "Ik beleef weinig plezier aan mijn werk",
            "themas": [1, 2, 5, 6],
            "stegen": [{
                "id": "1-2-1",
                "kop": "Ik vind het werk dat ik doe niet zinvol, ik moet dingen doen waar ik niet achter sta.",
                "themas": [1, 2, 5]
            }, {
                "id": "1-2-2",
                "kop": "Ik heb het gevoel dat ik niet goed meer meekom, de arbeidsomstandigheden en werkdruk zijn zwaar.",
                "themas": [1, 2, 5]
            }, {
                "id": "1-2-3",
                "kop": "Ik heb het gevoel dat ik niet meer gewaardeerd wordt door mijn collega's of mijn leidinggevende.",
                "themas": [1, 2, 5]
            }, {
                "id": "1-2-4",
                "kop": "Ik heb het gevoel dat het werk dat ik doe niet meer gewaardeerd wordt.",
                "themas": [1, 2, 5, 6]
            }, {
                "id": "1-2-5",
                "kop": "Ik heb geen zin meer in cursussen en allerlei vernieuwingen.",
                "themas": [1, 2, 5]
            }, {
                "id": "1-2-6",
                "kop": "Ik voel me niet thuis bij de huidige werkcultuur, mijn organisatie is zo veranderd dat ik er niet meer bij pas.",
                "themas": [1, 2]
            }]
        }, ]
    }, {
        "id": "2",
        "naam": "Mijn werkgever",
        "themas": [1, 2, 3, 6],
        "straten": [{
            "id": "2-1",
            "kop": "Mijn werkgever wil dat ik blijf",
            "themas": [1, 2, 3],
            "stegen": [{
                "id": "2-1-1",
                "kop": "Ik heb een leidinggevende die mij niet kwijt wil.",
                "themas": [1,2,3]
            }, {
                "id": "2-1-2",
                "kop": "Mijn leidinggevende geeft me de ruimte om mijn werk naar wens aan te passen, bijvoorbeeld met flexibele tijden.",
                "themas": [1, 2, 3]
            }]
        }, {
            "id": "2-2",
            "kop": "Wat mijn werkgever betreft, kan ik gaan",
            "themas": [3, 6],
            "stegen": [{
                "id": "2-2-1",
                "kop": <span>Ik heb het gevoel (of weet zeker) dat mijn leidinggevende/&thinsp;manager/&thinsp;werkgever van mij af wil.</span>,
                "themas": [6]
            }, {
                "id": "2-2-2",
                "kop": "Mijn werkgever biedt geen mogelijkheden om na mijn AOW-leeftijd door te werken.",
                "themas": [6]
            }, {
                "id": "2-2-3",
                "kop": "Ik kan nu gebruik maken van een vertrekregeling en je weet nooit of dat over een jaar nog zo is.",
                "themas": [3, 6]
            }, {
                "id": "2-2-4",
                "kop": "Mijn leidinggevende wil mij detacheren of een outplacement-traject starten.",
                "themas": [6]
            }]
        }]
    },
    {
        "id": "3",
        "naam": "Mijn pensioengevoel",
        "themas": [1, 2, 5],
        "straten": [{
            "id": "3-1",
            "kop": "Ik kijk uit naar mijn pensioen",
            "themas": [1, 2, 5],
            "stegen": [{
                "id": "3-1-1",
                "kop": "Ik wil vrijheid en geen verplichtingen meer.",
                "themas": [1, 5]
            }, {
                "id": "3-1-2",
                "kop": "Ik wil meer tijd voor leuke dingen, zoals studie, mijn hobby's, reizen, familie en vrienden.",
                "themas": [1, 2, 5]
            }]
        }]
    }, {
        "id": "4",
        "naam": "Mijn geld",
        "themas": [1, 2, 3, 4, 5],
        "straten": [{
            "id": "4-1",
            "kop": "Ik kan beter doorwerken, vanwege het geld",
            "themas": [1, 2, 3, 4, 5],
            "stegen": [{
                "id": "4-1-1",
                "kop": "Ik heb hoge vaste lasten, of wil geld kunnen besteden aan vakanties en andere leuke dingen.",
                "themas": [1, 2, 3, 4, 5]
            }, {
                "id": "4-1-2",
                "kop": "Mijn partner is al met pensioen, daardoor is ons inkomen flink gedaald.",
                "themas": [1, 2, 3, 4, 5]
            }, {
                "id": "4-1-3",
                "kop": "Mijn pensioen wordt te laag als ik voor mijn AOW-leeftijd stop met werken.",
                "themas": [1, 2, 3, 4, 5]
            }, {
                "id": "4-1-4",
                "kop": "Ik durf niet te stoppen, want ik heb geen overzicht over mijn pensioensituatie, AOW-leeftijd of indexatie.",
                "themas": [1, 2, 3, 4, 5]
            }, {
                "id": "4-1-5",
                "kop": "Ik verlies mijn voorwaardelijk pensioen als ik nu stop met werken.",
                "themas": [1, 2, 3, 4, 5]
            }, {
                "id": "4-1-6",
                "kop": "Ik bouw een hoger pensioen op als ik doorwerk.",
                "themas": [1, 2, 3, 4, 5]
            }, {
                "id": "4-1-7",
                "kop": "Door mijn scheiding heeft mijn ex recht op een (flink) deel van mijn pensioen.",
                "themas": [1, 2, 3, 4, 5]
            }]
        }, {
            "id": "4-2",
            "kop": "Ik kan de inkomensdaling van (deeltijd)pensioen opvangen",
            "themas": [1, 2, 3, 4, 5],
            "stegen": [{
                "id": "4-2-1",
                "kop": "Ik heb vermogen opgebouwd, bijvoorbeeld door sparen, lijfrente, een erfenis, of overwaarde op mijn huis.",
                "themas": [1, 2, 3, 4, 5]
            }, {
                "id": "4-2-2",
                "kop": "Het inkomen van mijn partner is goed.",
                "themas": [1, 2, 3, 4, 5]
            }, {
                "id": "4-2-3",
                "kop": "Mijn vaste lasten kunnen omlaag, bijvoorbeeld door zuiniger leven of omdat mijn hypotheek is afgelost.",
                "themas": [1, 2, 3, 4, 5]
            }, {
                "id": "4-2-4",
                "kop": "Ik vul de inkomensverlaging aan met ABP KeuzePensioen; als ik volledig stop, is mijn pensioen een paar procent lager.",
                "themas": [1, 2, 3, 4, 5]
            }, {
                "id": "4-2-5",
                "kop": "Ik kan mijn pensioen verhogen door het partnerpensioen over te hevelen, mijn partner heeft een goed pensioen of ik heb een andere overlijdensrisicoverzekering.",
                "themas": [1, 2, 3, 4]
            }, {
                "id": "4-2-6",
                "kop": "Ik krijg een deel van het pensioen van mijn ex.",
                "themas": [1, 2, 3, 4]
            }, {
                "id": "4-2-7",
                "kop": "Ik heb overige inkomsten, bijvoorbeeld als zelfstandige.",
                "themas": [1, 2, 3, 4, 5]
            }]
        }]
    }, {
        "id": "5",
        "naam": "Mijn privéleven",
        "themas": [5],
        "straten": [{
            "id": "5-1",
            "kop": "Mijn werk en mijn privéleven zijn in balans",
            "themas": [5],
            "stegen": [{
                "id": "5-1-1",
                "kop": "Zolang mijn partner nog werkt, wil ik niet alleen thuis zitten.",
                "themas": [5]
            }]
        }, {
            "id": "5-2",
            "kop": "Ik wil meer tijd voor mijn privéleven",
            "themas": [5],
            "stegen": [{
                "id": "5-2-1",
                "kop": "Ik wil mijn kinderen graag helpen in hun drukke bestaan, bijvoorbeeld met oppassen.",
                "themas": [5]
            }, {
                "id": "5-2-2",
                "kop": "Ik wil meer tijd besteden aan andere zaken, zoals mijn hobby's en vrijwilligerswerk.",
                "themas": [5]
            }]
        }]
    }, {
        "id": "6",
        "naam": "De maatschappij",
        "themas": [5],
        "straten": [{
            "id": "6-1",
            "kop": "Mijn huidige maatschappelijke positie bevalt me goed",
            "themas": [5],
            "stegen": [{
                "id": "6-1-1",
                "kop": "Het werk dat ik doe, verschaft mij een bepaalde positie die ik niet wil verliezen.",
                "themas": [5]
            }]
        }]
    }, {
        "id": "7",
        "naam": "Mijn gezondheid",
        "themas": [5, 6],
        "straten": [{
            "id": "7-1",
            "kop": "Vanwege mijn gezondheid wil ik stoppen",
            "themas": [5, 6],
            "stegen": [{
                "id": "7-1-1",
                "kop": "Ik vind het werk lichamelijk of geestelijk te zwaar worden.",
                "themas": [5]
            }, {
                "id": "7-1-2",
                "kop": "Ik verwacht dat mijn gezondheid achteruit gaat.",
                "themas": [5]
            }, {
                "id": "7-1-3",
                "kop": "De situatie op mijn werk - ik heb het gevoel dat ze van me af willen - is slecht voor mijn gezondheid.",
                "themas": [5, 6]
            }]
        }]
    }
]

export { wijken }
