import React from 'react';
import Header from '../Header/Header';
import ShareButtons from '../ShareButtons/ShareButtons';
import Modal from '../Modal/Modal';
import Wijk from '../Wijk/Wijk';
import Button from '../Button/Button';

import './OptionScreen.scss';

const OptionScreen = (props) => {

	const thema = props.data.themas[props.data.huidigeThema-1];
	const text = thema.tekst2;
	const nextPage = () => props.history.push("/stap3");

	const wijken = props.data.wijken
		.filter((wijk) => {
			return (wijk.themas.indexOf(props.data.huidigeThema) > -1)}
			)
		.map((wijk, i) => (
			<Wijk key={"wijk"+wijk.id} wijk={wijk} data={props.data} updateScore={props.updateScore}  />
		));

	return (
		<div className="optionscreen">
            <Header {...props}  />
            <ShareButtons />

            <div className='wrapper'>
                <h3 className='page-title'>Uw persoonlijke afwegingen</h3>

                <div className="wijken">
                    <h6>{text} Geef voor elke stelling aan of u deze belangrijk, neutraal of niet belangrijk vindt en klik dan op 'ga verder'.</h6>
                    {wijken}
                    <span className='go-right'>
                        <Button text="Ga verder" icon="pijl" iconPosition='left' clickHandler={nextPage} />
                    </span>
                </div>
            </div>

            <Modal open={props.data.modal} toggleModal={props.toggleModal} />
		</div>
	)
}

export default OptionScreen;
